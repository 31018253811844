<template>
  <v-tabs-items v-model="tab" touchless>
    <v-tab-item :value="1">
      <order-details
        :items="items"
        enable-delete-link
        enable-clear-link
        @delete="(item) => removeItem(item)"
        @clear="() => clearCart()"
      >

        <template #actions="{ count }">
          <v-btn
            color="primary"
            class="text-capitalize font-600 mb-4"
            block
            @click="() => {
              tab = 2;
              checkIsScheduleable();
            }"
            :disabled="count < 1"
            v-if="tab == 1"
          >
            {{ $t('GuestCheckout.buyNow') }}
          </v-btn>
        </template>

      </order-details>
    </v-tab-item>

    <v-tab-item v-for="(appointment, index) in appointments" :key="index" :value="index + 2">
      <div class="text-uppercase font-weight-bold mb-4">{{ appointment.producto }}</div>

      <n-form :ref="`appointment-${index}`">
        <n-appointment v-model="appointment.fechaApt" :entityId="appointment.entidadId"
          :productId="appointment.productoId"
          :commerceId="appointment.tblCnfComercio && appointment.tblCnfComercio.comercioId" :rules="[ $v.required ]"
          @date-selected="(day, hour, minute) => handleDateSelected(day, hour, minute, index)"
          @days-updated="handleDaysUpdated"></n-appointment>
      </n-form>

      <v-btn color="primary" class="text-capitalize font-600 mb-4" block @click="() => validar(`appointment-${index}`)">
        {{ $t('GuestCheckout.next') }}
      </v-btn>
    </v-tab-item>

    <v-tab-item :value="appointmentsCount + 2">
      <n-form ref="orderForm">

        <template v-if="shippingCount > 0">
          <n-date-picker v-model="delivery.date" :label="$t('Checkout.deliveryDate')" :rules="[ $v.required ]"></n-date-picker>

          <n-time-picker v-model="delivery.time" :label="$t('Checkout.deliveryTime')" :rules="[ $v.required ]"></n-time-picker>

          <n-address v-model="location" :label="$t('Checkout.deliveryAddress')" v-if="!noScheduleable"></n-address>

          <n-textarea v-model="tblLgtOc.instrucciones" :label="$t('Checkout.deliveryInstructions')" :placeholder="$t('Checkout.deliveryInstructionsExample')" :rules="[ $v.required ]"></n-textarea>
        </template>

        <n-field v-model="tblLgtOc.nombreContacto" label="Fullname" prepend-inner-icon="mdi-account" :rules="[ $v.required ]"></n-field>

        <n-field v-model="tblLgtOc.email" label="Email" prepend-inner-icon="mdi-email" :rules="[ $v.required, $v.email ]"></n-field>

        <n-field v-model="tblLgtOc.telefono" label="Phone" prepend-inner-icon="mdi-phone" :rules="[ $v.required, $v.phone ]"></n-field>

        <v-col cols="12" sm="12" md="12">
          <v-checkbox v-model="showBilling" label="Billing information"></v-checkbox>
        </v-col>

        <template v-if="showBilling">
          <n-field v-model="tblLgtOc.rfc" label="RFC" :rules="[ $v.required ]"></n-field>

          <n-field v-model="tblLgtOc.razonSocial" label="Nombre o razon social" :rules="[ $v.required ]"></n-field>

          <n-field v-model="tblLgtOc.invoiceEmail" label="Correo electronico" :rules="[ $v.required, $v.email ]"></n-field>

          <n-choice v-model="tblLgtOc.regimenFiscalId" label="Regimen fiscal" source="api/v1/fct/regimenesfiscal" item-text="label" item-value="valor" :return-object="false" :rules="[ $v.required ]"></n-choice>

          <n-choice v-model="tblLgtOc.usoCfdi" label="Uso CFDI" source="api/v1/fct/usoscfdi" item-text="label" item-value="valor" :return-object="false" :rules="[ $v.required ]"></n-choice>

          <n-field v-model="tblLgtOc.cp" label="Codigo postal" :rules="[ $v.required ]"></n-field>
        </template>

        <v-col cols="12" xl="12" lg="12">
          <v-btn
            color="primary"
            class="text-capitalize font-600 mb-4"
            block
            @click="() => validateOrderForm('orderForm')"
            v-if="tab == (appointmentsCount + 2)"
            :loading="isLoading"
            :disabled="isLoading"
          >
            {{ $t('GuestCheckout.placeOrder') }}
          </v-btn>
        </v-col>
      </n-form>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import { mapState } from 'vuex';
import OrderDetails from "@/components/OrderDetails";
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    OrderDetails,
  },
  computed: {
    ...mapState({
      items: (state) => state.guest.items,
    }),
    appointmentsCount() {
      return _.sumBy(this.items, function (a) {
        if (a.esAgendable === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
    },
    appointments() {
      return this.items.filter((a) => a.esAgendable === 'Y');
    },
    shippingCount() {
      return _.sumBy(this.items, function (a) {
        if (a.esAgendable === 'N') {
          return 1;
        } else {
          return 0;
        }
      });
    },
    location: {
      get() {
        let tmp = {};

        let { direccion, latitud, longitud } = this.tblLgtOc;

        if (direccion && direccion != null && direccion != '') tmp.address = direccion;
        if (latitud && latitud != null && latitud != '') tmp.lat = latitud;
        if (longitud && longitud != null && longitud != '') tmp.lng = longitud;

        return tmp;
      },
      set(v) {
        _.set(this.tblLgtOc, 'direccion', v.address);
        _.set(this.tblLgtOc, 'latitud', v.lat);
        _.set(this.tblLgtOc, 'longitud', v.lng);

        // this.$set(this.tblLgtOc, 'direccion', v.address);
        // this.$set(this.tblLgtOc, 'latitud', v.lat);
        // this.$set(this.tblLgtOc, 'longitud', v.lng);
      }
    }
  },
  data() {
    return {
      isLoading: false,
      tab: 1,
      tblLgtOc: {},
      showBilling: false,
      delivery: {
        date: moment().format("YYYY-MM-DD"),
        time: moment().add(1, "hours").format("HH:mm"),
      },
      noScheduleable: false,

      fechaAptInicio: '', // PUEDE NO NECESITAR SER REACTIVA
      fechaAptFin: '',  // PUEDE DESAPARECER (REVISION)

      dateRangeItems: [],
      deletedIndexes: [],

      days: [],
    }
  },
  mounted() {
    this.$store
      .dispatch("guest/getCart").then(() => {
        // DOES NOTHING
      }).catch((err) => {
        console.log('[CART] Error getting shopping cart')
      });
  },
  methods: {
    clearCart() {
      this.$store.dispatch("guest/clearCart");
    },
    removeItem(item) {
      this.$store.dispatch("guest/removeItem", item);
    },
    validateOrderForm(ref) {
      this.$refs[ref].validate().then(() => {
        this.generateOrder();
      }).catch((err) => {
        this.$console.log('[VALIDATION]', err);
      })
    },
    generateOrder() {
      // SE OBTIENE LA FECHA Y HORA DE LA ENTREGA
      let date = this.delivery.date;
      let time = this.delivery.time;

      // SE BUSCA SI HAY ALGUN OBJETO CON UN RANGO DE FECHA
      if (this.dateRangeItems.length > 0) {
        this.deleteReferenceInItems();
        this.tblLgtOc.orderDetails = [...this.dateRangeItems, ...this.items];
      } else {
        this.tblLgtOc.orderDetails = this.items;
      }

      // SE ESTABLECEN LOS PARAMETROS ADICIONALES DE LA ORDEN DE COMPRA
      this.tblLgtOc.fechaOc = `${date} ${time}`;
      this.tblLgtOc.tblEntEntidad = {
        entidadId: this.$route.params.entidadId,
      };

      // SE ACTIVA LA ANIMACION DE CARGA
      this.isLoading = true;

      axios
        .post(`api/v1/public/orders`, this.tblLgtOc)
        .then((res) => {
          // SE OBTIENEN LOS DETALLES DE LA ORDEN DE MANERA SEGURA
          let order = _.get(res, "data.data", {});

          this.$store.dispatch("guest/clearCart");

          // SE ENVIA A LA PANTALLA DE PAGO UNIFICADA
          this.$router.push(`/orders/${order.ocId}/payments`).catch(() => { });
        })
        .catch((err) => {
          // SE MUESTRA EL MENSAJE DE ERROR EN CONSOLA DE DEPURACION
          this.$console.error("CHECKOUT =>", err.toString());

          // SE MUESTRA UN DIALOG CON EL MENSAJE DE ERROR
          this.$dialog({
            title: this.$t("dialog.error"),
            text: "Hubo un error al intentar realizar la compra, por favor intentelo de nuevo",
          });
        })
        .finally(() => {
          // SE DESACTIVA LA ANIMACION DE CARGA
          this.isLoading = false;
        });
    },
    handleDateSelected(day, hour, minute, index) {
      let strDate = `${day} ${hour}:${minute}`;
      let dateArray = strDate.split(',');

      if (dateArray.length == 1) {
        this.fechaAptInicio = strDate;
        //BUSQUEDA EN RANGEITEMS DE PRODUCTOS EXISTENTES PARA SU ELIMINACION CUANDO SE ELIGE UN NUEVO RANGO EN EL CALENDARIO 1 FECHA
        if (this.dateRangeItems.length > 0) {
          //console.log('reinicio de rango - entra fecha en items');
          this.dateRangeItems = this.dateRangeItems.filter((item) => {
            if (item.prdId !== this.appointments[index].prdId) {
              return true;
            } else {
              this.deletedIndexes.splice(index, 1);
              return false;
            }
          });
        }

      } else if (dateArray.length > 1) {
        if (this.fechaAptInicio != '') {
          let dateStartObject = new Date(this.fechaAptInicio); //new Date(auxDateStartStr);
          let formattedStartDate = this.formatDate(dateStartObject);

          let dateEndObject = new Date(dateArray[1]);
          let formattedEndDate = this.formatDate(dateEndObject);

          if (dateStartObject < dateEndObject) {
            this.appointments[index].fechaApt = formattedStartDate + ',' + formattedEndDate;
          } else if (dateStartObject > dateEndObject) {
            this.appointments[index].fechaApt = formattedEndDate + ',' + formattedStartDate;
          } else {
            this.appointments[index].fechaApt = formattedStartDate + ',' + formattedEndDate;
          }
          const dateRange = this.getDateRange(index);
          if (dateRange.length > 0) {
            //console.log('DOS FECHAS');
            this.appointments[index].fechaApt = formattedStartDate;

            //BUSQUEDA EN RANGEITEMS DE PRODUCTOS EXISTENTES PARA SU ELIMINACION CUANDO SE ELIGE UN NUEVO RANGO EN EL CALENDARIO2 2 FECHAS
            this.dateRangeItems = this.dateRangeItems.filter((item) => item.prdId !== this.appointments[index].prdId);

            //SE CREAN LOS NUEVOS OBJETOS QUE ABARCAN EL RANGO DE FECHAS PARA LA RESERVAS
            this.dateRangeItems = [...this.dateRangeItems, ...dateRange.map(date => {
              return {
                ...this.appointments[index],
                fechaApt: date
              };
            })];
            this.deletedIndexes.push(index);
          }
        }
      }
    },
    /**
     * VALIDA DINAMICAMENTE LOS FORMULARIOS DE CADA TAB
     */
    validar(reference) {
      this.$refs[reference][0].validate()
        .then(() => {
          // CAMBIA AL SIGUITE TAB
          this.tab++;
        })
        .catch(() => {
          // DOES NOTHING
        });
    },
    getDateRange(index) {
      let dateRange = [];
      let dateStrings = this.appointments[index].fechaApt.split(',');
      dateStrings.forEach(dateString => {
        const [startDateStr] = dateString.trim().split(',');
        const startDate = new Date(startDateStr);
        let aux = this.formatDate(startDate);
        dateRange.push(aux);
      });

      if (dateRange.length === 2) {
        const endDate = new Date(dateRange[1]);
        // Restar un día a la última fecha del rango
        if (dateRange.length > 1) {
          //console.log('eliminacion de fecha en rango');
          let indice = dateRange.indexOf(dateRange[1]);
          // Si se encuentra el valor, eliminarlo
          if (indice !== -1) {
            dateRange.splice(indice, 1);
          }
        }

        const currentDate = new Date(dateRange[0]);
        // Agregar fechas al rango hasta alcanzar la fecha de fin
        while (currentDate < endDate) {
          currentDate.setDate(currentDate.getDate() + 1);
          if (currentDate <= endDate) {
            let validateDate = this.formatBasicDate(currentDate);
            if (this.days.includes(validateDate)) {
              dateRange.push(this.formatDate(currentDate));
            } else {
              console.log('NO EXISTE EN ARRAY DAYS');
            }
          }
        }
      }
      //console.log('GUEST FECHAS INSERTADAS', dateRange);
      return dateRange;
    },
    deleteReferenceInItems() {
      this.deletedIndexes.sort((a, b) => b - a);
      this.deletedIndexes.forEach(index => {
        this.items.splice(index, 1);
      });
    },
    handleDaysUpdated(arrayDays) {
      this.days = arrayDays;
    },
    formatDate(dataObject) {
      let year = dataObject.getFullYear();
      let month = dataObject.getMonth() + 1;
      let day = dataObject.getDate();
      let hours = dataObject.getHours();
      let minutes = dataObject.getMinutes();

      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    },
    formatBasicDate(date) {
      let año = date.getFullYear();
      let mes = (date.getMonth() + 1).toString().padStart(2, '0');
      let dia = date.getDate().toString().padStart(2, '0');

      return `${año}-${mes}-${dia}`;
    },
    checkIsScheduleable() {
      this.noScheduleable = this.items.some(item => item.esAgendable === 'Y');
    },
  },
}
</script>