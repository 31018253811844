<template>
  <n-panels>
    <template #center>
      <n-grid
        :label="$t('Home.title1')"
        :src="productsUrl"
        :filters="{
          'producto': filters.producto
        }"
        :filter-operators="{
          'producto': 'like'
        }"
        order-by="precioUnitario"
        col-sm="12"
        col-md="12"
        col-lg="12"
        col-xl="12"
      >
        <template #append-header="{ isLoading }">
          <v-toolbar-items>
            <v-text-field v-model="filters.producto" :placeholder="$t('AppBar.search')" outlined rounded dense
              clearable hide-details single-line prepend-inner-icon="mdi-magnify" :disabled="isLoading"></v-text-field>
          </v-toolbar-items>
        </template>

        <template #item="{ item }">
          <v-card class="shadow-sm">
            <v-list-item>
              <v-img
                :src="item.imagen || '/img/default_product_image.png'"
                class="mr-4"
                max-width="52"
                min-width="52"
                :aspect-ratio="1"
              ></v-img>

              <!-- <product-details :content-img="item.imagen || '/img/default_product_image.png'" :title="item.producto" :amount="item.cantidadEmpaque"
                :store="$_.get(item, 'tblCnfComercio.comercio', 'Generico')" :salePrice="item.precioNeto">
                <template #activator="{ attrs, on }">
                  <v-img v-bind="attrs" v-on="on" :src="item.imagen || '/img/default_product_image.png'" class="mr-4"
                    max-width="52" min-width="52" :aspect-ratio="1"></v-img>
                </template>
              </product-details> -->

              <v-list-item-content>
                <span class="text-uppercase font-weight-regular text-caption">
                  <v-icon small v-if="item.esAgendable === 'Y' && item.esCalendarizado == 'Y'">mdi-calendar-check</v-icon>
                  <v-icon small
                    v-else-if="item.esAgendable == 'Y' && item.esCalendarizado == 'N'">mdi-calendar-remove</v-icon>
                  {{ item.producto }}
                </span>

                <div class="font-weight-regular text-caption" v-text="item.tblLgtGrpProducto.grpProducto" v-if="item.tblLgtGrpProducto"></div>

                <div class="font-weight-bold text-caption" v-text="`$ ${(item.precioNeto || 0).toFixed(2)}`" v-if="item.precioNeto"></div>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  outlined
                  class="rounded"
                  color="primary"
                  :disabled="(item.esAgendable == 'Y' && item.esCalendarizado == 'N') || internalLoading"
                  @click="() => addItem(item)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </template>
      </n-grid>
    </template>

    <template #right>
      <guest-checkout-details></guest-checkout-details>
    </template>
  </n-panels>
</template>

<script>
/**
 * NOTAS:
 * 1. SE SEPARA LA LOGICA DE LOS DETALLES DE ORDEN DE COMPRA COMO INVITADO A GUEST_CHECKOUT_DETAILS
 * 2. SE IMPLEMENTA NGRID PARA SCROLL INFINITO DE PRODUCTOS
 */
import _ from 'lodash';
import NPanels from '../../components/newgen/NPanels.vue';
import CardCart from "../../components/cartCard/CardCart";
import ProductDetails from '../../components/cartCard/ProductDetails.vue';
import GuestCheckoutDetails from './GuestCheckoutDetails.vue';

export default {
  components: {
    NPanels,
    CardCart,
    ProductDetails,
    GuestCheckoutDetails
  },
  computed: {
    productsUrl() {
      let url = `api/v1/public/entities/${this.$route.params.entidadId}/products`;

      if (this.$route.query && this.$route.query.comercioId) {
        url += `?tblCnfComercio.comercioId=${this.$route.query.comercioId}`
      }

      return url;
    }
  },
  data() {
    return {
      internalLoading: false,
      filters: {
        precioUnitario: [0, 10000],
        categories: [],
        sortBy: 'asc',
        producto: ''
      }
    };
  },
  methods: {
    addItem(item) {
      this.$store.dispatch("guest/increaseQuantity", item)
        .then(() => {
          // DOES NOTHING
        })
        .catch(() => {
          // DOES NOTHING
        })
        .finally(() => {
          this.internalLoading = false;
        })
    },
  },
};
</script>