<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on">
        <v-btn icon color="secondary" v-bind="attrs" v-on="on">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </slot>
    </template>

    <base-card>
      <v-card-title>
        <v-btn class="mt-4" absolute right icon color="secondary" text @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-img height="200" :src="contentImg || '/img/default_product_image.png'" contain />
          </v-col>
          <v-col cols="12" md="6">
            <div class="mt-4 mb-4 text-h6 font-weight-black">
              {{ title }}
            </div>

            <h3 class="font-weight-bold primary--text mb-3">
              $ {{ salePrice.toFixed(2) }}
            </h3>

            <h5 class="grey--text text--darken-5 mb-3">
              {{ $t('CardCart.stock') }}
            </h5>

            <h5 class="grey--text text--darken-5 mb-3">
              {{ $t('CardCart.amount') }}:
              <span class="grey--text text--darken-1 font-weight-medium ml-2">{{ amount }}</span>
            </h5>

            <h5 class="grey--text text--darken-5 mb-3">
              {{ $t('CardCart.soldBy') }}:
              <span class="grey--text text--darken-1 font-weight-medium ml-2">{{ store }}</span>
            </h5>
          </v-col>
        </v-row>
      </v-card-text>
    </base-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    contentImg: {
      type: String,
    },
    title: {
      type: String
    },
    salePrice: {
      type: Number
    },
    amount: {
      type: Number
    },
    store: {
      type: String
    }
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>