var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('n-panels',{scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('n-grid',{attrs:{"label":_vm.$t('Home.title1'),"src":_vm.productsUrl,"filters":{
        'producto': _vm.filters.producto
      },"filter-operators":{
        'producto': 'like'
      },"order-by":"precioUnitario","col-sm":"12","col-md":"12","col-lg":"12","col-xl":"12"},scopedSlots:_vm._u([{key:"append-header",fn:function(ref){
      var isLoading = ref.isLoading;
return [_c('v-toolbar-items',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('AppBar.search'),"outlined":"","rounded":"","dense":"","clearable":"","hide-details":"","single-line":"","prepend-inner-icon":"mdi-magnify","disabled":isLoading},model:{value:(_vm.filters.producto),callback:function ($$v) {_vm.$set(_vm.filters, "producto", $$v)},expression:"filters.producto"}})],1)]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-card',{staticClass:"shadow-sm"},[_c('v-list-item',[_c('v-img',{staticClass:"mr-4",attrs:{"src":item.imagen || '/img/default_product_image.png',"max-width":"52","min-width":"52","aspect-ratio":1}}),_c('v-list-item-content',[_c('span',{staticClass:"text-uppercase font-weight-regular text-caption"},[(item.esAgendable === 'Y' && item.esCalendarizado == 'Y')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-check")]):(item.esAgendable == 'Y' && item.esCalendarizado == 'N')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-remove")]):_vm._e(),_vm._v(" "+_vm._s(item.producto)+" ")],1),(item.tblLgtGrpProducto)?_c('div',{staticClass:"font-weight-regular text-caption",domProps:{"textContent":_vm._s(item.tblLgtGrpProducto.grpProducto)}}):_vm._e(),(item.precioNeto)?_c('div',{staticClass:"font-weight-bold text-caption",domProps:{"textContent":_vm._s(("$ " + ((item.precioNeto || 0).toFixed(2))))}}):_vm._e()]),_c('v-list-item-action',[_c('v-btn',{staticClass:"rounded",attrs:{"icon":"","outlined":"","color":"primary","disabled":(item.esAgendable == 'Y' && item.esCalendarizado == 'N') || _vm.internalLoading},on:{"click":function () { return _vm.addItem(item); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)]}}])})]},proxy:true},{key:"right",fn:function(){return [_c('guest-checkout-details')]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }